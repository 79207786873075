.toggle {
	position: relative;
  align-self: flex-end;
	margin-bottom: 16px;

	&-label {
		position: relative;
		-webkit-tap-highlight-color: transparent;
	}

	&-on,
	&-off {
		position: absolute;
		height: 16px;
		bottom: 6px;
		z-index: 1;
	}

	&-off {
		left: 5px;
	}

	&-on {
		right: 5px;
	}
	
	&-input {
		opacity: 0;

		&,
		&:after,
		&:before,
		& *,
		& *:after,
		& *:before,
		& + .toggle-label {
			box-sizing: border-box;
			&::selection {
				background: none;
			}
		}
		
		+ .toggle-label {
			outline: 0;
			display: block;
			width: 54px;
			height: 28px;
			position: relative;
			background: #000;
			border-radius: 20px;
			padding: 2px;
			cursor: pointer;
			user-select: none;
			transition: all .4s ease;
			&:after,
			&:before {
				position: relative;
				content: "";
				display: block;
				width: 24px;
				height: 24px;
				z-index: 2;
			}
			
			&:after {
				left: 0;
				border-radius: 50%;
				background: #fff;
				transition: all .2s ease;
			}
			
			&:before {
				display: none;
			}
		}
	
		&:focus + .toggle-label {
			&::before,
			&::after {
				box-shadow: 0px 0px 3px 3px rgba(#ff9f43, 1);
			}
		}
	
		&:active + .toggle-label {
			&::before,
			&::after {
				box-shadow: 0px 0px 6px 4px rgba(#ff9f43, 1);
			}
		}
		
		&:checked + .toggle-label:after {
			left: 50%;
		}
	}
}